<template>
  <div v-if="nativeEvent">
    <v-menu
      v-model="showMenu"
      absolute
      offset-y
      :position-x="x"
      :position-y="y"
      transition="scale-transition"
      max-width="500px"
    >
      <v-list
        dense
        class="grey lighten-5"
        :height="items.length > 0 ? items.length * 45 + 'px' : '100px'"
      >
        <v-list-item
          v-for="(item, x) in items"
          :key="x"
          :title="item.tooltip ? item.tooltip : item.text"
          :disabled="item.disabled ? item.disabled : false"
          @click="eleccion(item)"
        >
          <v-list-item-content class="text-left">
            <v-list-item-title class="ml-0 align-stretch">
              {{ item.text.substring(0, max)
              }}{{ item.length > max ? "..." : "" }}
            </v-list-item-title>
            <v-spacer></v-spacer>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: ["nativeEvent", "event", "action", "optionsArr"],
  data() {
    return {
      showMenu: false,
      items: [],
      x: -1000,
      y: -1000,
      max: 150,
    };
  },
  watch: {
    showMenu: function (val, prev) {
      //console.log(val + "  " + prev);
      if (val == false && prev == true) {
        this.destroy();
      }
    },
  },
  beforeMount() {
    let ls = new Array();
    if(this.action){
        switch (this.action.toLowerCase()) {
          case "event-options": // this.event property requerido cuando proviene de Agenda.vue
            if (this.isCita()) {
              let end = Date.parse(this.event.end.substring(0, 10));
              let allowed = true; //isAllowedToAttendToConsultation(end);
              if (allowed) {
                ls.push({
                  key: "ir-a-consulta",
                  text: "Ir a consulta",
                });
                ////disabled: false,
              }
              ls.push({
                key: "ver-expediente",
                text: "Ver expediente del paciente",
              });

            }
            break;
          case "create-event":
            ls.push({
              key: "agendar-0",
              text: "Programar cita",
            });
            ls.push({
              key: "agendar-1",
              text: "Programar evento",
            });
            ls.push({
              key: "agendar-2",
              text: "Bloquear el día (No dar consultas)",
            });
            break;
          case "edit-delete":
            ls = [
              {
                key: "edit",
                text: "Editar",
              },
              {
                key: "delete",
                text: "Eliminar",
              },
            ];
            break;
          default:
            break;
        }
    }
    if (this.optionsArr && this.optionsArr.length > 0) {
      Array.prototype.push.apply(ls, this.optionsArr);
    }

    ls = ls.filter((e) => e.visible === undefined || e.visible === true);

    this.items = ls;
    this.x = this.nativeEvent.clientX; //  10
    this.y = this.nativeEvent.clientY; //  10

  },
  mounted() {
    if (this.items.length == 0) {
      this.destroy();
    } else {
      this.showMenu = true;
      setTimeout(() => {
        this.showMenu = false;
      }, 15000);
    }
  },
  methods: {
    eleccion: function (item) {
      this.showMenu = false;
      this.$emit("selected", item.key, this.event);
      this.destroy();
    },
    destroy: function () {
      this.$emit("destroy");
    },
    isCita: function () {
      return (
        this.event && this.event.tipo != undefined && this.event.tipo === 0
      );
    },
    isEvento: function () {
      return (
        this.event && this.event.tipo != undefined && this.event.tipo === 1
      );
    },
    isToday: function (str) {
      const d = str.substring(0, 11).split("-"); //yyyy-mm-dd hh:mm
      const h = str.substring(11, 16).split(":");
      const date = new Date(d[0], parseInt(d[1]) - 1, d[2], h[0], h[1], 0, 0);
      return (
        date.getTime() >= this.today().getTime() &&
        date.getTime() <= this.endToday().getTime()
      );
    },
  },
  beforeDestroy() {
    //console.log("--------  destroying ................");
  },
};
</script>